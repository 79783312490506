import React, {
  useEffect, useState,
} from 'react'
import { Container, Row } from 'reactstrap'
import axios from 'axios'
import DebatesBox from '../DebatesBox'
import { LOADER, GETURL } from '../../utils/constants'

import './style.scss'

const DebatesPagination = () => {
  const [isLoading, setLoading] = useState(true);
  const [topDebates, setTopDebates] = useState([])

  useEffect(() => {
    async function getTop50() {
      try {
        const top50 = `${GETURL}/debates?top50=true&order=asc&orderby=date`

        const requestOne = await axios.get(top50);

        setTopDebates(requestOne.data.debates)
        setLoading(false);
      } catch (error) {
        console.log(error.message)
      }
    }

    getTop50()
  }, [])

  return (
    <section className="top50">
      <Container aria-live="polite">
        <Row>
          {isLoading && (
            <div className="loadingSpinner"><img src={LOADER} alt="Carregando" /></div>
          )}
          {topDebates.map((top) => {
            const {
              _id, get_image, date, title, description,
            } = top;
            return (
              <DebatesBox
                key={_id}
                widthClass="col-12 col-lg-3 fade-in"
                background={get_image}
                dateHour={date}
                title={title}
                description={description}
                buttonUrl={`single/${_id}`}
                buttonText="Saiba mais"
                wordLimit={80}
              />
            );
          })}
        </Row>
      </Container>
    </section>
  )
}

export default DebatesPagination
