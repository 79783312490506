import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/seo'

import Hero from '../components/Hero'
import DebatesPagination from '../components/DebatesPagination'

const debatesPage = () => (
  <Layout>
    <Seo title="Debates in English | Talk of the Town" />

    <Hero text="Top 50 Debates" />

    <DebatesPagination />
  </Layout>
)

export default debatesPage
